import React, { useState } from "react";

import * as classes from "../get_started/timeline_success_road/timeline_success_road.module.scss";
import { useTranslation } from 'react-i18next';

const SuccessRoad = ({ title, Data, page, stylePage }) => {
  const [mapData, setMapData] = useState([...Data]);
  const {t} = useTranslation();
  return (
    <>
      <div className="font-sans flex flex-col mx-auto w-full">
        <div className="flex flex-row relative">
          {/* <img src="https://d2lxkizvrhu4im.cloudfront.net/images/dots_5.svg" className="absolute -top-10 -left-32"/> */}
          <div className="text-3xl md:text-5xl font-semibold w-11/12 md:w-5/6 text-center mx-auto my-10">
            {title}
          </div>
          {page !== "scalbility" ? null : (
            <img
              alt="left star icon"
              src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
              className="absolute -bottom-10 -right-20 hidden md:block"
            />
          )}
        </div>
        <div className="">
          {
            mapData.map((i, index) => {
              return (
                <div
                  className={
                    index % 2 === 0
                      ? ` flex flex-col md:flex-row justify-between items-center md:my-10 pb-12 `
                      : ` flex flex-col md:flex-row-reverse justify-between items-center pb-12 md:my-10 relative`
                    // index === 0
                    //   ? ` flex flex-col md:flex-row justify-between items-center md:my-10 pb-12 `
                    //     ? index === 1
                    //     : ` flex flex-col md:flex-row-reverse justify-between items-center pb-12 md:my-10 relative`
                    //     ? index === 2
                    //     : ` flex flex-col md:flex-row-reverse justify-between items-center  md:my-10 relative`
                    //   : ""
                  }
                >
                  <div
                    className={`ml-10  my-10 md:my-0 md:w-1/2 ${
                      index % 2 === 0 ? "mx-0" : "md:ml-10"
                    } relative`}
                  >
                    {page !== "wcs" ? (
                      <img
                        // style={{
                        //   display:
                        //     stylePage === "scalabilite" ? "hidden" : "flex",
                        // }}
                        alt="dot icon"
                        src="https://d2lxkizvrhu4im.cloudfront.net/images/dots_6.svg"
                        className={`  absolute  hidden md:block  ${
                          index % 2 === 0
                            ? "-left-40 -top-32  rotate-180 transform"
                            : "-right-24 -top-40"
                        }`}
                      />
                    ) : null}
                    <img
                      alt="get started"
                      src={i.imgSrc}
                      className={index === 2 ? classes.imgmobile : ""}
                      style={
                        index === 2
                          ? // ? { maxWidth: "480px", backgroundColor: "#f0f" }
                            { maxWidth: "480px" }
                          : { maxWidth: "100%" }
                      }
                    />
                  </div>

                  <div className="flex flex-row md:text-left space-x-2 w-full md:w-1/2 ">
                    <div className="w-1/12 ">
                      <span className="relative">
                        <img
                          alt="get started"
                          src="https://d2lxkizvrhu4im.cloudfront.net/icons/donut_icon.svg"
                          className="w-full  md:w-2/3 mx-auto relative"
                        />
                        {checkPageAndStyles(stylePage, index)}
                      </span>
                    </div>
                    <div className="flex flex-col space-y-2  w-11/12">
                      <div className="text-2xl md:text-3xl font-semibold w-11/12  md:w-4/5">
                        {t(i.title)}
                      </div>
                      <div className="text-gray-500 text-sm md:text-xl leading-loose" dangerouslySetInnerHTML={{__html: t(i.description)}}>
                        {/* {i.description} */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    </>
  );
};

export default SuccessRoad;
export const checkPageAndStyles = (stylePage, index) => {
  if (stylePage === "support") {
    if (index === 0) {
      return (
        <div className={classes.supportRightCurve}>
          <div className={classes.supportLeftCurve}></div>
        </div>
      );
    } else if (index === 1) {
      return (
        <div className={classes.supportLeftCurve2}>
          <div className={classes.supportRightCurve2}></div>
        </div>
      );
    }
  } else if (stylePage === "scalabilite") {
    if (index === 0) {
      return (
        <div className={classes.scalabiliteRightCurve}>
          <div className={classes.scalabiliteLeftCurve}></div>
        </div>
      );
    } else if (index === 1) {
      return (
        <div className={classes.scalabiliteLeftCurve2}>
          <div className={classes.scalabiliteRightCurve2}></div>
        </div>
      );
    }
  } else if (stylePage === "security") {
    if (index === 0) {
      return (
        <div className={classes.securityRightCurve}>
          <div className={classes.securityLeftCurve}></div>
        </div>
      );
    } else if (index === 1) {
      return (
        <div className={classes.securityLeftCurve2}>
          <div className={classes.securityRightCurve2}></div>
        </div>
      );
    }
  }
};

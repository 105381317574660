import React from "react";

import TopBanner from "../top_banner/platform_top_banner_2";
import Ready from "../ready/ready";
import PrivacyFeatures from "../features/privacyFeatures";
import SuccessRoad from "../get_started/success_road";
import Review from "../reviews/reviews";
import Subscribe2 from "../subscribe/subscribe_2";
import BannerWrapper from "../top_banner/banner_wrapper";

import * as classes from "../top_banner/top_banner.module.scss";
import { useTranslation } from 'react-i18next';
// import TimelineSuccessRoad from "../get_started/timeline_success_road/timeline_success_road";

const PlatformWCS = () => {
  const {t} = useTranslation();
  const ReviewsData = [
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/s_test_1.jpg",
      name: "Sophie Laborde-Balen",
      designation: "Fondatrice chez Réseau TonAvenir",
      reviewTitle: `${t('what_they_say_about_mereos')}`,
      review:
        "Je suis toujours épatée par le niveau de réactivité et de disponibilité que l'équipe d'assistance Mereos est capable de fournir, tous les jours et à toute heure",
    },
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/s_test_2.jpg",
      name: "David Gabai",
      designation: "COO chez Storelift",
      reviewTitle: `${t('what_they_say_about_mereos')}`,
      review:
        "Que ce soit avec notre équipe opérationnelle ou pour répondre à des questions techniques, le soutien que nous avons reçu et continuons de recevoir de la part Mereos ne peut être qualifié que d'exceptionnel",
    },
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/s_test_3.jpg",
      name: "Mike Kelland",
      designation: "Associé chez Lightbend",
      reviewTitle: `${t('what_they_say_about_mereos')}`,
      review:
        "Mereos nous apporte une expertise technique approfondie ainsi que des conseils pour améliorer en continu notre certification numérique, son coût et son déploiement. Mereos soutient notre stratégie à long terme visant à fournir des solutions modernes à nos clients",
    },
  ];

  const wcsData = [
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/rocket_pointing_upwards.svg",
      title: "Onboarding",
      description:
        `${t('our_support_team_implements_your_solution')}`
    },
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/rocket_with_person_with_laptop.svg",
      title: `${t('follow_up')}`,
      description:
        `${t('you_get_24_access_to_our_multi_regional')}`,
    },
    {
      imgSrc: "https://d2lxkizvrhu4im.cloudfront.net/images/rocket_with_flag_on_planet.svg",
      title: `${t('arriving_safely')}`,
      description:
        `${t('your_long_term_success_is_our_ultimate_goal')}`,
    },
  ];

  const PaymentData = [
    {
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/globe_in_message_icon_with_bg.svg",
      title: `${t('multilingual')}`,
      description:
        `${t('give_your_candidates_the_benefit_of_localized')}`,
    },
    {
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/headphone_icon_with_bg.svg",
      title: `${t('available_24_and_ultra_efficient')}`,
      description:
        `${t('our_support_is_available_every_day')}`,
    },
    {
      icon: "https://d2lxkizvrhu4im.cloudfront.net/icons/thumbs_up_icon_with_bg.svg",
      title: `${t('proactivity')}`,
      description:
        `${t('our_support_spontaneously_offers_help')}`,
    },
  ];
  return (
    <>
      <div className={`conatiner font-sans`}>
        <BannerWrapper>
          <div
            className={` flex flex-col md:flex-row  justify-center items-center h-full py-16  mx-auto ${classes.wrapper}`}
          >
            <div
              className={`flex flex-col lg:flex-row  justify-center items-center w-full `}
            >
              <div
                className={`w-full lg:w-6/12 md:mb-32 lg:mb-0 flex flex-col lg:items-end  p-0 h-full `}
              >
                <div
                  className={`flex flex-col w-full md:w-10/12   space-y-2 md:space-y-5 mx-auto md:mx-0 items-center lg:items-start  mb-10 md:mb-0`}
                >
                  <div
                    className={`text-xs md:text-base font-semibold text-center lg:text-left  opacity-80`}
                  >
                    Support
                  </div>
                  <div
                    className={`font-bold text-3xl md:text-6xl  w-11/12 text-center lg:text-left  md:w-10/12`}
                  >
                    <h1>{t('experts_available_24_and_multilingual')}</h1>
                  </div>
                  <div
                    className={` text-sm md:text-xl  font-medium  text-center  lg:text-left leading-relaxed px-1 md:px-0 w-11/12 md:w-1/2 lg:w-10/12`}
                  >
                   {t('our_experts_are_committed_to_helping_your_evaluation')}
                  </div>
                </div>
              </div>
              <div
                className={`w-9/12  lg:w-6/12 bg-cover  bg-no-repeat p-0 md:my-0 h-full mx-auto flex flex-col items-center md:items-start`}
              >
                <img
                  src={
                    "https://d2lxkizvrhu4im.cloudfront.net/images/platform_top_banner_girl_looking_at_mobile_laptop.svg"
                  }
                  alt="img banner"
                  className=" m-0 p-0 w-9/12"
                  style={{ maxHeight: "550px", maxWidth: "750px" }}
                />
              </div>
            </div>
          </div>
        </BannerWrapper>

        {/* sub Container */}
        <div className={`mx-auto w-11/12 lg:w-10/12`}>
          <div className="my-20">
            <PrivacyFeatures
              title={t('5_star_international_support')}
              DataArray={PaymentData}
            />
          </div>
          <div className="my-20">
            {/* <TimelineSuccessRoad
              title=" L’autoroute de la satisfaction client"
              Data={wcsData}
              page="wcs"
            /> */}
            <SuccessRoad
              title={t('the_highway_to_customer_satisfaction')}
              Data={wcsData}
              page="wcs"
              stylePage="support"
            />
          </div>
        </div>

        <Review Data={ReviewsData} />

        <div className={`mx-auto w-11/12 lg:w-5/6 `}>
          <div className=" mx-auto my-20">
            <Ready />
          </div>
        </div>
        <Subscribe2 />
      </div>
    </>
  );
};

export default PlatformWCS;

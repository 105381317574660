import * as React from "react";

import Layout from "../components/layout/layout";
import PlatformWCSComponent from "../components/platform/platform_wcs";
import Seo from "../components/seo";
import { useTranslation } from 'react-i18next';

const PlatformWCS = () => {
  const {t} = useTranslation();
  return (
    <>
      <Seo
        title="Support 24/7"
        description={t('reach_your_goals_more_easily_with_mereos')}
      />
      <Layout>
        <PlatformWCSComponent />
      </Layout>
    </>
  )
}

export default PlatformWCS
